.raw-editor {
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    height: auto;
    min-height: 56px;
}

.text-editor{
    position: relative;
}

.toolbar{
    position: absolute;
    top:6px;
    right: 12px;
    cursor: pointer;
    .source-btn{
        background: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 30px;
    }
}

.upload-button{
    position: absolute;
    top:6px;
    right: 80px;
    cursor: pointer;
    .source-btn{
        background: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 30px;
    }
}

.quill{
    .ql-editor{
        min-height: 240px;
    }
}