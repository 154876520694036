.search-container{
  //  width: 70%;
   padding-left: 170px;
      .searchbar{
          height: 32px;
          width: 350px;
          padding-right: 32px;
          padding-left: 6px;
          background: #FBFCFC 0% 0% no-repeat padding-box;
          border: 1px solid #6C6B6B;
          border-radius: 5px;
          opacity: 1;
      }
      .search-icon{
          position: relative;
          right: 24px;
          color:#414141;
      }
     .searchbar:focus-visible{
         outline: none;
     }
  }
  
  @media (min-width:320px) and (max-width:490px){
      .search-container{
        .searchbar{
          width:  82%;
          height: 28px;
        }
      }
  }
  
  @media (min-width:490px) and (max-width:620px){
      .search-container{
        .searchbar{
          width: 76%;
          height: 32px;
        }
      }
  }
  