.divider-hitbox {
  cursor: col-resize;
  align-self: stretch;
  display: flex;
  align-items: center;
  /* padding: 0 1rem; */
  width: 10px;
  background: #414141 0% 0% no-repeat;
  opacity: 1;
  height: 100%;

}

.simulationDiv {
  background: red;
}

.statisticsDiv {
  background: blue;
}

.divider {
  height: 100vh;
  border: 1px solid #808080;
  width: auto;
}

.rightPane {
  flex: 1;
}

.splitView {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.App {
  display: block;
  /* border: 1px solid; */
  /* width: 400px; */
  height: 100vh;
  font-family: "Franklin Gothic Medium", Arial, sans-serif;
}

.Resizer {
  z-index: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  align-self: stretch;
  align-items: center;
  background: #050505 0% 0% no-repeat;
  opacity: 1;
  height: 100%;

}

.Resizer.vertical {
  width: 1.5%;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;

}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.disabled {
  cursor: col-resize;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

@media (min-width: 1920px) {
  .Resizer.vertical {
    width: 1%;
  }

  .Resizer.vertical:hover {
    width: 1%;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.disabled {
    cursor: col-resize;
    width: 1%;

  }

  .Resizer.disabled:hover {
    cursor: col-resize;
    width: 1%;
  }
}
.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip::before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip:hover::before {
  display: block;
}
.custom-content canvas{
  max-width: 100%;
}

.video-js {
  width: 500px;
  height: 360px;
}
