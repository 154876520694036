.dropdown-content {
    position: absolute;
    background-color: #FBFCFC;
    min-width: 266px;
    padding: 16px 12px 16px 12px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
    right: 0;
    top: 62px;

    .profile-container {
        .profile-header {
            display: flex;
            flex-direction: column;
            padding-bottom: 6px;

            .user-name {
                text-align: left;
                font: normal normal 600 14px/20px Open Sans;
                letter-spacing: 0px;
                color: #414141;
                opacity: 1;
            }

            .user-details {
                text-align: left;
                font: normal normal normal 14px/20px Open Sans;
                letter-spacing: 0px;
                color: #414141;
                opacity: 1;
            }
            
        }

        .submenu-header {
            display: flex;
            padding-bottom: 6px;
            border-bottom: 1px solid #ccc;

            .backarrow {
                width: 22px;
                height: 22px;
                margin-right: 14px;
                transform: rotate(90deg);
                cursor: pointer;
            }
        }

        .user-roles {
            .menu {
                display: flex;
                cursor: pointer;

                .menu-icon {
                    width: 20px;
                    height: 20px;
                }

                .menu-name {
                    margin-left: 16px;
                    width: 100%;
                    text-align: left;
                    font: normal normal normal 14px/32px Open Sans;
                    letter-spacing: 0px;
                    color: #414141;
                    opacity: 1;
                }

                .theme-label {
                    
                    width: 95px;
                    height: 19px;
                    letter-spacing: 0px;
                    color: #414141;
                    text-align: left;
                    font: normal normal 600 14px/20px Open Sans;
                    letter-spacing: 0px;
                    opacity: 1;
                }

                .accordian {
                    width: 22px;
                    height: 22px;
                    transform: rotate(-90deg);
                }

                .sub-menu {
                    display: flex;

                }
            }
        }
    }
  
}