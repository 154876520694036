    .sc-portal-container{
    .topStrip{
        display: flex;
        justify-content: center;
        margin: auto 0;
        background: #bd0912;
        width: 76px;
        height:18px;
        border-radius: 0px 0px 4px 4px;
        margin: auto;
        margin-bottom: 8px;
        cursor: pointer;
        .arrow-up{
            color: #fff;
            height: 20px;
            transform: rotate(180deg);
        }
        .arrow-down{
            color: #fff;
            height: 20px;
        }
    }
     
    .leftCatalog{
        background: #bd0912;
        display: flex;
        height: 28px;
        position: relative;
        width: 38%;
        left: 50%;
        transform: translate(-50%, 0px);
        border-radius: 0px 0px 4px 4px;
        .catalog-icons{
            color:#fff;
            margin: auto;
            cursor: pointer;
        }
    }
    .sc-portal-block{
        display: flex;
    .instructions-container{
        width:32%;
        padding:8px;
        .instructions-header{
            display: flex;
            border-bottom: 1px solid #71757b;
            .heading{
                width: 90%;
                font-size: 20px;
                color:#bd0912;
            }
            .close-icon{
                text-align: right;
                .cross-icon{
                    cursor: pointer;
                     font-size: 20px;
                     font-weight: 100;
                }
            }
        }
    } 
    .instructions-widget{
        max-height: calc(100vh - 7vh);
        overflow-y: scroll;

    }

    .instructions-widget p{
        margin: 5px 0px;
    }

    .instructions-widget ul{
        margin: 0px;
    }

    .instruction-icon{
        width: 2%;
        margin: auto;
        font-size: 22px;
        padding-right: 2px;
        padding-left: 2px;
        color: #bd0912;
        cursor: pointer;
    } 
    .iframe-container{
        width: 100vw;
        height: calc(100vh);
    } 
    .iframe-container-expanded{
        width: 100vw;
        height: calc(100vh);
    }
    .Resizer {
        background: #000;
        opacity: 0.2;
        z-index: 1;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-background-clip: padding;
        -webkit-background-clip: padding;
        background-clip: padding-box;
      }
      
      .Resizer:hover {
        -webkit-transition: all 2s ease;
        transition: all 2s ease;
      }
      .Resizer.vertical {
        width: 11px;
        border-left: 5px solid rgba(255, 255, 255, 0);
        border-right: 5px solid rgba(255, 255, 255, 0);
        cursor: col-resize;
      }
      
      .Resizer.vertical:hover {
        border-left: 5px solid rgba(0, 0, 0, 0.5);
        border-right: 5px solid rgba(0, 0, 0, 0.5);
        width: 11px;
      } 
  }
}